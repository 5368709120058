/**
 * angular imports
 */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
/**
 * ngRx imports
 */
import { Store } from '@ngrx/store';
import * as SignupAction from '../../../store/actions/signup/signup.actions';
import * as SignupSelector from '../../../store/selectors/signup/signup.selectors';
import * as attendantAction from '../../../store/actions/attendant/attendant.action';
import * as attendant from '../../../store/selectors/attendant/attendant.selector';
/**
 * service imports
 */
import { Subscription } from 'rxjs';
import { HelperService } from '../../../helper.service';
/**
 * constants / models imports
 */
import { Country } from '../../../models/sign-up-chatbot.model';
import { PATTERN, USER_TYPE } from '../../../constant/app.constant';

@Component({
  selector: 'pap-attendant-login',
  templateUrl: './attendant-login.component.html',
  styleUrls: ['./attendant-login.component.scss']
})
export class AttendantLoginComponent implements OnInit, OnDestroy {

  /**
   * view otp1 child.
   */
  @ViewChild('otp1')
  private otp1: ElementRef;
  /**
   * view otp2 child.
   */
  @ViewChild('otp2')
  private otp2: ElementRef;
  /**
   * view otp3 child.
   */
  @ViewChild('otp3')
  private otp3: ElementRef;
  /**
   * view otp4 child.
   */
  @ViewChild('otp4')
  private otp4: ElementRef;
  /**
   * Used for resend otp view counter value.
   */
  public counterValue = 0;
  /**
   * Used for resend otp counter.
   */
  public counter = 0;
  /**
   * Used for resend otp counter interval.
   */
  public interval = 0;
  /**
   * show phone number at otp component
   * as masked
   */
  public maskedLoginInput: string;
  public defaultCountry: Country;

  /**
   * subscriptions
   */
  public otpRequestSub: Subscription;
  public attendantLoginErrorSub: Subscription;
  public verifyOtpSub: Subscription;
  public resendOtpSub: Subscription;
  public countriesSub: Subscription;

  /**
   * error message from login form
   */
  public apiErrorMsg = '';
  /**
   * form groups for login and otp forms
   */
  public mobileForm: FormGroup;
  public otpForm: FormGroup;
  /**
   * toggle to check if the form is valid
   */
  public isValid = false;
  /**
   * toggle to show otp component
   */
  public showOtpComponent = false;
  /**
   * stores current token for request otp
   */
  public currentOtpToken = '';
  /**
   * show loader
   */
  public showLoader = false;
  /**
   * total count of current user
   * clicked for resend otp
   */
  public verifyOtpCnt = 0;
  /**
   * countries list
   */
  public countries: Country[] = [];


  constructor(
    private fb: FormBuilder, private store: Store,
    private router: Router,
    private formBuilder: FormBuilder,
    public helperService: HelperService
  ) {}


  ngOnInit(): void {
    /**
     * initialize login form
     */
    this.mobileForm = this.fb.group({
      countryCode: ['65', this.defaultCountry],
      phone_number: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[0-9]+$/),
          Validators.maxLength(16),
          Validators.minLength(8)
        ]
      ],
      user_type: [USER_TYPE.ATTENDANT]
    });

    /**
     * turn off the interceptor for login and signup
     * APIs
     */
    this.helperService.addCustomAuthToken = true;
    /**
     * load countries
     */
    this.store.dispatch(SignupAction.loadCountries({}));
    this.countriesSub = this.store.select(SignupSelector.getCountryList).subscribe(data => {
      if (data !== null) {
        this.countries = data.list;
      }
    });
    /**
     * manage errors
     */
    this.attendantLoginErrorSub = this.store.select(attendant.getAttendantApiError).subscribe(data => {
      if (data) {
        this.showLoader = false;
        this.apiErrorMsg = data;
        this.store.dispatch(attendantAction.resetErrors());
      }
    });

    /**
     * initialize form
     */
    this.otpForm = this.formBuilder.group({
      otp1: ['', [Validators.required]],
      otp2: ['', [Validators.required]],
      otp3: ['', [Validators.required]],
      otp4: ['', [Validators.required]]
    });
    /**
     * verify otp subscription
     */
    this.verifyOtpSub = this.store.select(attendant.verifyAttendantRequestOTP).subscribe(data => {
      if (data) {
        if (data.is_registered === 1) {
          localStorage.setItem('attendantToken', data?.token_details?.accessToken);
          localStorage.removeItem('attendantAuth');
          this.router.navigate(['/attendant/dashboard']);
          const encryptedUserDetails = btoa(JSON.stringify(data.user_detail));
          localStorage.setItem('attendantUserDetails', encryptedUserDetails);
        } else {
          localStorage.setItem('attendantAuth', data.token);
          this.router.navigate(['/attendant/register']);
        }
      }
    });

    /**
     * resend otp response subscription
     */
    this.resendOtpSub = this.store.select(attendant.attendantResendOTP).subscribe((res) => {
      if (res) {
        this.counterValue = 20;
        this.counter = 20;
        this.interval = 1000;
        this.store.dispatch(attendantAction.resendOTPData({ resendOtpStatus: false }));
      }
    });

    /**
     * request otp subscription
     */
    this.otpRequestSub = this.store.select(attendant.getAttendantRequestOTP).subscribe(otpToken => {
      if (otpToken) {
        /**
         * start resend otp timer
         */
        this.counterValue = 20;
        this.counter = 20;
        this.interval = 1000;

        this.showLoader = false;
        this.currentOtpToken = otpToken;
        this.showOtpComponent = true;
        /**
         * save incoming token to localstorage
         */
        const loginInput = this.mobileForm.get('phone_number')?.value;
        /**
         * save current attendant phone number to common service
         */
        if (this.helperService.attendantCurrentPhoneNumber !== loginInput) {
          this.helperService.attendantCurrentPhoneNumber = loginInput;
          this.verifyOtpCnt = 0;
        }
        // localStorage.setItem('phoneNumber', this.loginInput.toString());

        this.maskedLoginInput = loginInput.substring(0, loginInput.length - 4).replace(/\w/g, 'X')
          + loginInput.substring(loginInput.length - 4, loginInput.length);
      }
    });
  }

  /**
   * Submit confirm otp api.
   */
  public enterConfirmOtp(): void {
    this.showLoader = true;
    this.verifyOtpCnt += 1;
    const otp = this.otpForm.get('otp1').value + this.otpForm.get('otp2').value
      + this.otpForm.get('otp3').value + this.otpForm.get('otp4').value;
    this.store.dispatch(attendantAction.verifyOTP({ otp, otpRequestToken: this.currentOtpToken }));
    this.apiErrorMsg = '';
    this.otpForm.reset();
  }


  getCountryInfo(): void {
    switch (localStorage.getItem('country')) {
      case 'SG':
        this.defaultCountry = {
          code: localStorage.getItem('country'),
          countryCode: 65,
          id: 5,
          logo: 'assets/images/singapore-signup-icon.png\n',
          name: 'singapore'
        };
        break;
      default:
        break;
    }
  }

  /**
   * Receive counter value.
   */
  receiveCounterValue(event): void {
    this.counterValue = event;
    if (this.counterValue === 0) {
      this.counter = 0;
    }
  }

  /**
   * on resend otp
   */
  public resendOtp(): void {
    this.apiErrorMsg = '';
    this.store.dispatch(attendantAction.resendOTP({ otpRequestToken: this.currentOtpToken }));
  }

  /**
   *  Used to auto focus.
   */
  keytab(event, index: number): void {
    const numbers = PATTERN.WHOLE_NUMBER_REGEX;
    if (event.code === 'Backspace' || event.key.match(numbers)) {
      if (index === 1) {
        event.code === 'Backspace' ? this.otp1.nativeElement.focus() : this.otp2.nativeElement.focus();
      } else if (index === 2) {
        event.code === 'Backspace' ? this.otp1.nativeElement.focus() : this.otp3.nativeElement.focus();
      } else if (index === 3) {
        event.code === 'Backspace' ? this.otp2.nativeElement.focus() : this.otp4.nativeElement.focus();
      } else {
        event.code === 'Backspace' ? this.otp3.nativeElement.focus() : this.otp4.nativeElement.focus();
      }
    }
  }

  /**
   * on submit the login form
   */
  public onSubmitLoginForm(): void {
    this.showLoader = true;
    this.store.dispatch(attendantAction.loadOTP({
      countryCode: this.mobileForm.get('countryCode')?.value as string,
      phoneNumber: this.mobileForm.get('phone_number')?.value as string,
      userType: this.mobileForm.get('user_type')?.value as string
    }));
    this.apiErrorMsg = '';
  }

  /**
   * to validate no. on bases of event
   * @param event keyboard event
   */
  validateNumberLength(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
      return;
    }
    if (!/^\d$/.test(event.key)) {
      event.preventDefault();
      return;
    }
    if (input.value.length === 0 && event.key === '0') {
      event.preventDefault();
      return;
    }
    if (input.value.length >= 15) {
      event.preventDefault();
      return;
    }
  }

  backEditAttendantLoginPage(): void {
    this.apiErrorMsg = '';
    this.showOtpComponent = false;
    this.otpForm.reset();
    this.verifyOtpCnt = 0;
  }

  onInputChange(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    if (input.length >= 8 && input.length <= 15 && !input.startsWith('0') && /^\d+$/.test(input)) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
    this.apiErrorMsg = '';
  }

  backToAttendantLoginPage(): void {
    this.router.navigate(['/attendant/login/']);
  }

  public moveToNext(currentInput, nextInputId): void {
    if (currentInput.value.length === 1) {
      document.getElementById(nextInputId).focus();
    }
  }

  onKeyUp(event: KeyboardEvent, index: number): void {
    const input = event.target as HTMLInputElement;
    if (input.value.length === 1 && index < 3) {
      const nextInput = document.getElementById('otp' + (index + 1));
      if (nextInput) {
        nextInput.focus();
      }
    }
    if (event.key === 'Backspace' && input.value.length === 0 && index > 0) {
      const prevInput = document.getElementById('otp' + (index - 1));
      if (prevInput) {
        prevInput.focus();
      }
    }
  }

  /**
   * unsubscribe subscriptions
   */
  private unsubscribeVariables(): void {
    if (this.countriesSub) { this.countriesSub.unsubscribe(); }
    if (this.resendOtpSub) { this.resendOtpSub.unsubscribe(); }
    if (this.verifyOtpSub) { this.verifyOtpSub.unsubscribe(); }
    if (this.otpRequestSub) { this.otpRequestSub.unsubscribe(); }
    if (this.attendantLoginErrorSub) { this.attendantLoginErrorSub.unsubscribe(); }
  }

  ngOnDestroy(): void {
    this.unsubscribeVariables();
    this.store.dispatch(attendantAction.destroyToken());
    this.helperService.addCustomAuthToken = false;
  }

}
