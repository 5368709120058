import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ErrorAction from '../../actions/error/error.actions';
import * as AttendantAction from '../../actions/attendant/attendant.action';
import { ActivatedRoute, Router } from '@angular/router';
import { API } from '../../../constant/api.constant';
import { Store } from '@ngrx/store';
import * as LoginAction from '../../actions/login/login.actions';
import { AttendantReqOtpResponse, AttendantVerifyOtpResponse } from '../../../models/responses.model';
import { HelperService } from '../../../helper.service';


@Injectable()
export class AttendantEffects {

  authToken = '';


  // privateHelperService: any;
  constructor(private actions$: Actions, privateHelperService: HelperService,
              private http: HttpClient, private router: Router, private store: Store,
              private route: ActivatedRoute, ) {
      // let authToken = this.privateHelperService?.getAttendantToken;
    }

  /**
   * Effect for loading otp.
   */
   public loadOTP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttendantAction.loadOTP),
      switchMap((action) =>
        this.http.post(API.ATTENDANT_OTP_REQUEST, {
          country_code: action.countryCode,
          phone_number: action.phoneNumber,
          user_type: action.userType,
        }).pipe(
          map((response: AttendantReqOtpResponse) => {
            if (response.success) {
              return AttendantAction.setOtp({ otpRequestToken: response.data.token });
            }
          }),
          catchError(err => {
            return [
              AttendantAction.setErrors({
                error: err.error.message
              }),
            ];
          })
        )
      )
    )
  );



/**
 * verifyOtp
 */
  public verifyOTP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttendantAction.verifyOTP),
      switchMap((action) =>
        this.http.post(API.ATTENDANT_OTP_VERIFY, {
          otp: action.otp,
        },
        {
          headers: {
            Authorization: 'Bearer ' + action.otpRequestToken
          }
        }).pipe(
          map((response: AttendantVerifyOtpResponse) => {
            if (response.success) {
              return AttendantAction.setVerifyOTPData({ verifyOtpResponse: response.data });
            }
          }),
          catchError(err => {
            return [
              AttendantAction.setErrors({
                error: err.error?.message || 'Verification failed',
              }),
              ErrorAction.catchError({
                error: err,
              }),
            ];
          })
        )
      )
    )
  );
/**
 * resend otp
 */
  public resendOTP$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(AttendantAction.resendOTP),
        mergeMap((action) =>
          this.http.get(
            API.ATTENDANT_OTP_RESEND,
             {
              headers: {
                Authorization: 'Bearer ' + action.otpRequestToken
              }
          },
          ).pipe(
            map((response: AttendantReqOtpResponse) => {
              if (response.success) {
                return AttendantAction.resendOTPData({ resendOtpStatus: true });
              }
            }),
            catchError(err => {
              return [AttendantAction.setErrors(
                { error: err.error?.error?.messages?.length > 0 ? err.error?.error?.messages[0] : err.error?.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );

/**
 * nurse details form
 */
  submitForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttendantAction.submitForm),
      switchMap((action) => {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${action.otpRequestToken}`,
        });
        return this.http.post(API.ATTENDANT_PERSONAL_DETAILS, {
          first_name: action.firstName,
          last_name: action.lastName,
          email: action.email,
          pharma_id: action.pharmaId,
        }, { headers }).pipe(
          map((response: AttendantVerifyOtpResponse) => {
            if (response.success) {
              return AttendantAction.registerFormSuccess({ response: response.data });
            }
          }),
          catchError((error) => of(AttendantAction.submitFormFailure({ error })))
        );
      })
    )
  );


/**
 * add nurse through dcpm
 */
  public addAttendantDCPM$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttendantAction.addAttendantDCPM),
      mergeMap((action) =>
        this.http
          .post(
            API.ADD_ATTENDANT_DCPM,
            {
              country_code: action.country_code,
              phone_number: action.phone_number,
              first_name: action.first_name,
              last_name: action.last_name,
              email: action.email,
              pharma_id: action.pharma_id,
              is_edit: action.is_edit,
            },
            // { headers: { 'login-auth': action.token } }
          )
          .pipe(
            map((res: any) => {
              if (res) {
                return AttendantAction.addAttendtSuccess({ response: res?.data?.user_detail });
              }
            }),
            catchError((err) => {
              console.log('first error -------> ', err);
              return [
                AttendantAction.setErrors({ error: err.error }),
              ];
            })
          )
      )
    )
  );

  /**
   * attendant-list
   */
  public loadPatientList$ = createEffect(() => this.actions$
  .pipe(
    ofType(AttendantAction.loadAttendantList),
    switchMap((action) =>
      this.http.get(
        API.ATTENDANT_LIST, {
        params:
        {
          limit: action.limit,
          pharma_code: action.pharma_code,
          search: JSON.stringify(action.search)
        }
      }
      ).pipe(
        switchMap((resData: any) => {
          return [AttendantAction.searchSuccess({ attendantList: resData?.data?.attendants })];
        }),
        catchError(err => {
          return [AttendantAction.listFailure({
            error: err.error
          }),
          ErrorAction.catchError({ error: err })];
        }),
      )
    )
  ));



  // public addPatientSG$ = createEffect(() => this.actions$
  // .pipe(
  //   ofType(AttendantAction.patientFormCreate),
  //   switchMap((action) =>
  //     this.http.post(
  //       API.ADD_PATIENT_SG,
  //        action.formData

  //     ).pipe(
  //       switchMap((response: any) => {
  //         return [AttendantAction.patientFormSuccess({response})];
  //       }),
  //       catchError(err => {
  //         return [AttendantAction.setErrors({
  //           error: err.error
  //         }),
  //         ErrorAction.catchError({ error: err })];
  //       }),
  //     )
  //   )
  // ));
  public addPatientSG$ = createEffect(() => this.actions$
  .pipe(
    ofType(AttendantAction.patientFormCreate),
    switchMap((action) => {
      console.log('token', action.authToken);
      const headers = new HttpHeaders({
        Authorization: `Bearer ${action.authToken}`,
      });

      return this.http.post(
        API.ADD_PATIENT_SG,
        action.formData,
        { headers }
      ).pipe(
        switchMap((response: any) => {

          return [AttendantAction.patientFormSuccess({ response })];
        }),
        catchError(err => {
          return [
            AttendantAction.setErrors({
              error: err.error
            }),
            ErrorAction.catchError({ error: err })
          ];
        }),
      );
    })
  ));


  public updatePatientDetailsSg$ = createEffect(() => this.actions$
  .pipe(
    ofType(AttendantAction.updateSGTreatmentDetail),
    switchMap((action) =>
      this.http.post(
        API.UPDATE_PATIENT_DETAILS_sG + action.accessTokenValue + '/treatment',
         action.formData

      ).pipe(
        switchMap((response: any) => {
          return [AttendantAction.updateSGTreatmentDetailSuccess({response})];
        }),
        catchError(err => {
          return [AttendantAction.setErrors({
            error: err.error
          }),
          ErrorAction.catchError({ error: err })];
        }),
      )
    )
  ));


}
