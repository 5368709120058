import { createReducer, on } from '@ngrx/store';
import * as AttendantAction from '../../actions/attendant/attendant.action';
import { UserRegistrationStep } from '../../../models/attendant.model';


export interface State {
  attendant: any;
  otpRequestToken: string | null;
  userRegistrationDetails: UserRegistrationStep | null;
  otpResendStatus: boolean;
  attendantForm: any;
  error: any;
  attendantdcpm: any;
  attendantlist: any;
  patientFormSg: any;
  treatmentUpdateSg: any;
}
export const initialState: State = {
  attendant: null,
  otpRequestToken: null,
  userRegistrationDetails: null,
  otpResendStatus: false,
  attendantForm: null,
  error: null,
  attendantdcpm: null,
  patientFormSg: null,
  attendantlist: null,
  treatmentUpdateSg: null,
};
export const reducer = createReducer(
  initialState,
  on(AttendantAction.resetErrors, (state, payload) => ({
    ...state,
    error: initialState.error
  })),
  on(AttendantAction.setOtp, (state, payload) => ({
    ...state,
    otpRequestToken: payload.otpRequestToken
  })),
  on(AttendantAction.destroyToken, (state, payload) => ({
    ...state,
    otpRequestToken: initialState.otpRequestToken
  })),

  on(AttendantAction.setVerifyOTPData, (state, payload) => ({
    ...state,
    userRegistrationDetails: payload.verifyOtpResponse
  })),
  on(AttendantAction.resendOTPData, (state, payload) => ({
    ...state,
    otpResendStatus: payload.resendOtpStatus
  })),

  on(AttendantAction.setErrors, (state, payload) => ({
    ...state,
    error: payload.error
  })),
  on(AttendantAction.registerFormSuccess, (state, payload) => ({
    ...state,
    userRegistrationDetails: payload.response
  })),
  on(AttendantAction.addAttendtSuccess, (state, payload) => ({
    ...state,
    attendantdcpm: payload.response
  })),
  on(AttendantAction.addAttendReset, (state, payload) => ({
    ...state,
    attendantdcpm: initialState.attendantdcpm
  })),
  on(AttendantAction.patientFormSuccess, (state, payload) => ({
    ...state,
    patientFormSg: payload.response
  })),
  on(AttendantAction.updateSGTreatmentDetailSuccess, (state, payload) => ({
    ...state,
    treatmentUpdateSg: payload.response
  })),
  on(AttendantAction.resetVerifyOtp, (state, payload) => ({
    ...state,
    userRegistrationDetails: initialState.userRegistrationDetails
  })),
  on(AttendantAction.searchSuccess, (state, payload) => ({
    ...state,
    attendantlist: payload.attendantList
  })),
  on(AttendantAction.attendantListReset, (state, payload) => ({
    ...state,
    attendantlist: initialState.attendantlist
  })),

);



