export const API = {
  LOGIN: '/api/v1/users/login',
  SIGNUP_CONSENT: '/api/v1/consent/patient/',
  APPROVAL_LIST: '/api/v1/approver/pap/',
  PRISCRIPTION_DETAIL: '/api/v1/approver/pap/',
  REFRESH_TOKEN: '/api/v1/users/refresh',
  COUNTRIES: '/api/v1/country/',
  STORE_OPERATOR_CAREGIVER: '/api/v1/store/',
  PRESCRIPTION_APPROVE_REJECT: '/api/v1/approver/prescription/',
  STORE_OPERATOR_REQUEST_OTP: '/api/v1/store/care-giver/',
  STORE_OPERATOR_CONFIRM_OTP: '/api/v1/store/care-giver/',
  LOCK_PRESCRIPTION: '/api/v1/approver/pap/',
  PRESCRIPTION_DETAIL: '/api/v1/store/',
  DISBURSE_CALCULATE: '/api/v1/store/',
  DESPENCE_DRUG_ORDER: '/api/v1/store/',
  CANCEL_ORDER: '/api/v1/store/',
  CREATE_LOGIN_PASSWORD: '/api/v1/user/password',
  RESET_LOGIN_PASSWORD_REQUEST: '/api/v1/user/reset-password',
  FREEZE_UNFREEZE_PRESCRIPTION_DETAIL: '/api/v1/approver/pap/',
  FREEZE_UNFREEZE_PRESCRIPTION: '/api/v1/approver/prescription/',
  GET_SMS_DASHBOARD: '/api/v1/approver/otp',
  FORMS: '/api/v1/form',
  PATIENT_AUTH: '/api/v1/consent/patient/authentication',
  SEARCH_DRUG: '/api/v1/approver/pap/drugs',
  SEARCH_MRN: '/api/v1/approver/doctors',
  PROGRAMS: '/api/v1/programs',
  EDUCATION: '/api/v1/educations',
  SPECIALTY: '/api/v1/speciality',
  FILE: '/api/v1/file',
  DOCTOR_FORM_SUBMIT: '/api/v1/doctor/pap/profile',
  PATIENT_MIGRATION_LIST: '/api/v1/approver/migrations',
  ENABLE_PATIENT_MIGRATION: '/api/v1/approver/migration/',
  PATIENT_MIGRATION_GET: '/api/v1/store/pap-application',
  PATIENT_MIGRATION_POST: '/api/v1/store/pap-application',
  DOCTOR_OTP_REQUEST: '/api/v1/doctor/otp/request',
  ATTENDANT_OTP_REQUEST: '/api/v1/attendant/request-otp',
  DOCTOR_OTP_RESEND: '/api/v1/doctor/otp/resend',
  ATTENDANT_OTP_RESEND: '/api/v1/attendant/resend-otp',
  DOCTOR_OTP_VERIFY: '/api/v1/doctor/otp/verify',
  ATTENDANT_OTP_VERIFY: '/api/v1/attendant/verify-otp',
  ATTENDANT_PERSONAL_DETAILS: '/api/v1/attendant/submit-personal-detail',
  ADD_ATTENDANT_DCPM: '/api/v1/approver/attendant',
  ATTENDANT_LIST: '/api/v1/approver/attendant',
  UPDATE_PATIENT_DETAILS_SG: '/api/v1/approver/patient-update',
  ADD_PATIENT_SG: '/api/v1/approver/patient-create',
  UPDATE_PATIENT_DETAILS_sG: '/api/v1/consent/patient/',
  DOCTOR_GET_DETAIL: '/api/v1/doctor/pap/',
  PHARMA_DETAIL: '/api/v1/pharma/',
  USER_MAPED_PHARMA: '/api/v1/pharma/user/',
  DOCTOR_CONSENT_PHARMA: '/api/v1/doctor/pharma/',
  PRESCRIPTION_REMARK: '/api/v1/approver/comments/',
  CONSULTED_DOCTORS: '/api/v1/approver/patient/',
  APPROVER_PATIENT_LIST: '/api/v1/approver/patients',
  ADD_DOCTOR_PROFILE: '/api/v1/approver/doctor/pap/profile/',
  UPDATE_DOCTOR_PROFILE: '/api/v1/doctor/credentials/',
  HOSPITAL_LIST: '/api/v1/consent/patient/',
  CLINIC_LIST: '/api/v1/pharma/',
  HOSPITAL_USERS: '/api/v1/hospital/',
  HOSPITAL_AFFILIATION: '/api/v1/hospital',
  CREATE_ORDER: '/api/v1/approver/patient/',
  UPLOAD_MEDICAL_DOCUMENT: '/api/v1/approver/patient/',
  DELIVERY: '/api/v1/approver/prescription/',
  REQUEST_ORDER: '/api/v1/approver/prescription/',
  PATIENT_DETAILS: '/api/v1/approver/patient/',
  AE_REPORTING: '/api/v1/approver/aeReporting/',
  AE_FORM_LIST: '/api/v1/approver/formList/',
  FIND_DOCTOR: '/api/v1/approver/doctor/otp/request',
  PHARMA_PAP: '/api/v1/pharma/pap/',
  REQUEST_OTP: '/api/v1/consent/patient/',
  PATIENT_HOSPITAL_LIST: '/api/v1/approver/patient/',
  EDIT_PATIENT_TREATMENT_DETAIL: '/api/v1/approver/patient/',
  APPROVER_AE_REPORT_LIST: '/api/v1/approver/aeReporting/list/',
  GET_AE_REPORT: '/api/v1/approver/aeReporting/'
};
