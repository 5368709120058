import { createAction, props } from '@ngrx/store';

/**
 * Model imports.
 */
import {
  PrescriptionData,
  PrescriptionApproveRejectRequest,
  AddDrug,
  MrnSearchList,
  PrescriptionClass,
  DrugSelectedData,
  RemarksData,
  GetRemarksData,
  RemarksDataList,
  PatientDetailData
} from '../../models/prescription-detail';
import {
  DrugSearchList, DrugSearchListData, ErrorHandler, MrnSearchListData, MrnSearchListResponse,
  OtpData, Error, ConsultedDoctor, PatientListResponse, PatientPrescriptionResponse, AeReportListResponse,
  UserDetailListResponse
} from '../../../../../../models/responses.model';
// TODO:Pavan: data typing.
/**
 * Get home data list.
 */
export const loadHomes = createAction(
  'Home|API [Home] Loading data list',
  props<{ status: string, offset: string, searchKeyword: string, limit: string, pharma: string }>()
);

/**
 * Get patient data list.
 */
export const loadPatientList = createAction(
  'Home|API [Home] Loading patient data list',
  props<{ pharma: string, limit: string, page: string, keyword: string, authToken?: string }>()
);
/**
 * get the filtered patient list data.
 */
export const loadFilteredPatientList = createAction(
  'Home|API [Home] Loading filtered patient data list',
  props<{ limit: string, page: string, filters: string, pharma: string, keyword?: string , authToken?: string }>()
);

/**
 * Set patient list data.
 */
export const setPatientListData = createAction(
  'Home|API [Home page] set patient List data',
  props<{ PatientList: PatientListResponse }>()
);
/**
 * load patient list data.
 */
export const loadPatientDetails = createAction(
  'Home|API [Home page] load patient detail data',
  props<{ patientId: string , authToken?: string  }>()
);
/**
 * set patient list data.
 */
export const setPatientDetails = createAction(
  'Home|API [Home page] set patient detail data',
  props<{ patientDetails: PatientDetailData }>()
);
/**
 * reset patient list data.
 */
export const resetPatientListData = createAction(
  'Home|API [Home page] reset patient List data',
);

/**
 * Get home data list.
 */
export const loadMigrationData = createAction(
  'Home|API [PatientMigrationList] Loading data list',
  props<{ offset: string, limit: string, keyword?: string, method: string }>()
);
/**
 * enable patient migration.
 */
// TODO: Manish : do data typing.
export const setPatientMigration = createAction(
  'Home|API [PatientMigrationEnable] Patient Migration',
  props<{ drugs: any, userDetails: any, prescriptionId: string }>()
);
/**
 * Set home list data.
 */
export const setHomeListData = createAction(
  'Home|API [Home page] set Home List data',
  props<{ HomeList: any }>()
);

/**
 * Set home list data.
 */
export const setPatientMigrationList = createAction(
  'Home|API [PatientMgration Page] set Home List data',
  props<{ MigrationList: any }>()
);

/**
 * Set Enable success.
 */
export const setEnableSuccess = createAction(
  'HOME|API [Patientmigration Success] set success response',
  props<{ EnableSucess: boolean }>()
);

/**
 * Reset patient migration.
 */
export const reset = createAction(
  'HOME|API [Patientmigration Success] reset success flag',
  props<{}>()
);


/**
 * Set login error data in store.
 */
export const setHomeError = createAction(
  'ERROR|CATCH [Home page] Home error handler for effects',
  props<{ error: ErrorHandler }>()
);
export const setPostApiError = createAction(
  'ERROR|CATCH [Detail page Post Data] error handler for effects',
  props<{ error: ErrorHandler }>()
);
/**
 * ReSet login error data in store.
 */
export const reSetHomeError = createAction(
  'ERROR|Reset [Home page] Reset Home error handler for effects'
);
export const resetPostApiError = createAction(
  'ERROR|Reset [Home page] Reset Home error handler for effects'
);

/**
 * Get priscription detail.
 */
export const getPriscriptionDetail = createAction(
  'Home|API [Home] Loading Proscription detail data',
  props<{ id: string, freeze: boolean, pharma?: string , authToken?: string }>()
);


/**
 * Set priscription detail.
 */
export const setPriscriptionDetail = createAction(
  'Home|API [Home] Set Priscription detail data',
  props<{ detail: PrescriptionData }>()
);

/**
 * Reset prescription detail.
 */
export const reSetPriscriptionDetail = createAction(
  'Home|Reset [Home] Reset Priscription detail data'
);

/**
 * Prescription approve/ reject api.
 */
export const prescriptionApproveReject = createAction(
  'Home|API [Home] prescription approve/reject',
  props<{
    country: string;
    parameter: PrescriptionApproveRejectRequest,
    nextApplication: string,
    prescription: any,
    updaterUserRole: string,
    remarks: string[],
    status: string;
    updatedBy: number;
    process: string;
  }>()
);

/**
 * Lock prescription.
 */
export const lockPrescription = createAction(
  'Home|API [Home] lock prescription',
  props<{ id: string }>()
);

/**
 * Load presigned URL.
 */
export const loadFilePresignedUrl = createAction(
  'FILE|API [Home] Load presigned url',
  props<{ endPoint: string }>()
);

/**
 * use to load sms dashboard data.
 */
export const loadSmsDashboard = createAction(
  'Home get sms dashboard',
  props<{ keyword: string }>()
);
/**
 *  used to set sms dashboard data.
 */
export const setSmsDashboard = createAction(
  'Home set sms dashboard',
  props<{ data: OtpData }>()
);
/**
 * ReSet sms dashboard.
 */
export const resetGetSmsDashboard = createAction(
  'Reset [Home page] Reset SMS Dashboard for effects'
);

/**
 * use to load drug list.
 */
export const loadDrugList = createAction(
  '[Prescription Detail] get drug list',
  props<{ keyword: string }>()
);
/**
 * used to set drug list data.
 */
export const setDrugList = createAction(
  '[Prescription Details] set drug list',
  props<{ data: DrugSearchList }>()
);
/**
 * used to reset drug list.
 */
export const resetDrugList = createAction(
  '[Prescription Details] reset drug list'
);
/**
 * Action to set selected drug.
 */
export const setSelectedDrug = createAction(
  '[Prescription Details] set selected drug',
  props<{ data: DrugSelectedData }>()
);
/**
 * Action to reset selected drug.
 */
export const resetSelectedDrug = createAction(
  '[Prescription Details] reset set selected drug'
);
/**
 * Action to add prescription drug.
 */
export const addPrescriptionDrug = createAction(
  '[Prescription Details] Added drug',
  props<{ data: DrugSelectedData[] }>()
);
/**
 * Action to reset prescription drug.
 */
export const resetAddPrescriptionDrug = createAction(
  '[Prescription Details] Reset Added drug',
);
/**
 * Action to load mrn data from keyword.
 */
export const loadMrnSearchData = createAction(
  '[Prescription Details] Load Mrn Search Data',
  props<{ isMrnSearch: string; keyword: string }>()
);
/**
 * Action to set mrn data.
 */
export const setMrnSearchData = createAction(
  ' Set mrn data after search',
  props<{ data: MrnSearchListData[] }>()
);
/**
 * set mrn  search error data in store
 */
export const setMrnError = createAction(
  'set mrn error',
  props<{ error: ErrorHandler }>()
);
/**
 * reset mrn error
 */
export const resetMrnError = createAction(
  'reset mrn error'
);
/**
 * Action to reset mrn data.
 */
export const resetMrnSearchData = createAction(
  '[Prescription Details] reset search mrn data after'
);

/**
 * used to get remarks data.
 */
export const getRemarksData = createAction(
  '[Prescription Details] get remarks data',
  props<{ id: string, limit: number, page: number, refererType: string, authToken?: string }>()
);
/**
 * used to set remarks data.
 */
export const setRemarksData = createAction(
  '[Prescription Details] set remarks data',
  props<{ data: RemarksDataList }>()
);
/**
 * used to reset remarks data.
 */
export const resetRemarksData = createAction(
  '[Prescription Details] reset remarks data',
);

export const resetPatientDetailData = createAction(
  '[Patient Details] reset patient detail data',
);
/**
 * used to submit prescription remark data.
 */
export const postPrescriptionRemark = createAction(
  '[Prescription Details] post remarks data',
  props<{ comment: string, refererId: string, refererType: string }>()
);

/**
 * used to load patient prescription data.
 */
export const fetchPatientPrescription = createAction(
  '[Patient Details] load prescription data',
  props<{ enrollmentId: string, page: string, pageSize: string, authToken?: string }>()
);

/**
 * used to set patient prescription data.
 */
export const setPatientPrescription = createAction(
  '[Patient Details] set prescription data',
  props<{ list: PatientPrescriptionResponse }>()
);

/**
 * used to set patient prescription data.
 */
export const setCreateOrderId = createAction(
  '[CREATE ORDER] set create order id',
  props<{ programId: string }>()
);
/**
 * used to set patient medical document id.
 */
export const setMedicalDocumentId = createAction(
  '[UPLOAD DOCUMENT] set upload document id',
  props<{ documentId: string }>()
);
/**
 * used to set patient prescription data.
 */
export const resetProgramId = createAction(
  '[CREATE ORDER] reset create order id'
);
/**
 * used to set patient prescription data.
 */
export const resetDocumentId = createAction(
  '[UPLOAD DOCUMENT] reset document id'
);

/**
 * used to set patient prescription data.
 */
export const resetPatientPrescription = createAction(
  '[Patient Details] reset prescription data'
);

/**
 * used to delete prescription remark data.
 */
export const deletePrescriptionRemark = createAction(
  '[Prescription Details] delete remarks data',
  props<{ commentCode: string }>()
);

/**
 * used to load doctor consulted list.
 */
export const loadConsultedDoctors = createAction(
  '[Prescription Details] load doctor consulted list',
  props<{ prescriptionId: string , authToken?: string}>()
);

/**
 * Action to set consulted doctor list.
 */
export const setConsultedDoctors = createAction(
  '[Prescription Details] Set consulted doctors',
  props<{ data: ConsultedDoctor[] }>()
);
/**
 * Action for reset consulted doctors.
 */
export const resetConsultedDoctors = createAction(
  '[Prescription Details] Reset consulted doctors',
);
/**
 * actions to set patient status
 */
export const updatePatientStatus = createAction(
  '[Patient Details] Update patient status',
  props<{ enrollmentId: string, contactOn: number, status: string, interactionDate: number }>()
);
export const setPatientStatus = createAction(
  '[Patient Details] set patient status response',
  props<{ patientStatus: string | null }>()
);

export const createOrder = createAction(
  '[CREATE ORDER] POST the order',
  props<{
    enrollmentId: string; orderForm: {
      doctorConsentDate: number;
      doctorName: string;
      interactionId: string;
      receivedApplicationDate: number;
    }
  }>()
);

export const createMedicalDocument = createAction(
  '[POST MEDICAL DOCUMENT] POST the medical document',
  props<{
    enrollmentId: string; uploadMedicalDocument: {
      urls: string[],
      doctorName: string,
    }
  }>()
);

export const notifyPatient = createAction(
  '[Notify Patient] used to notify patient',
  props<{ prescriptionId: string; requestType: string; nextApplication: string; updaterUserRole: string }>()
);

export const setNotifyStatus = createAction(
  '[Notify Patient] set to notify patient status',
  props<{ notifyStatus: boolean }>()
);

export const resetNotifyStatus = createAction(
  '[Notify Patient] reset to notify patient status'
);
export const notifyPatientCta = createAction(
  '[Notify Patient CTA] used to notify patient',
  props<{ prescriptionId: string; requestType: string }>()
);

export const setNotifyStatusCta = createAction(
  '[Notify Patient CTA] set to notify patient status',
  props<{ notifyStatusCta: boolean }>()
);

export const resetNotifyStatusCta = createAction(
  '[Notify PatientCTA] reset to notify patient status'
);
export const resetDeliveryDate = createAction(
  '[Delivery date] reset to delivery date'
);
export const resetOrderNumber = createAction(
  '[Order Number] reset to order number'
);

export const requestDeliveryDate = createAction(
  '[Delivery date] GET delivery date',
  props<{ prescriptionId: string; requestType: string }>()
);

export const setDeliveryDate = createAction(
  '[Delivery date] set to delivery date',
  props<{ deliveryDate: string }>()
);

export const requestOrderNo = createAction(
  '[Order Number] GET order number',
  props<{ prescriptionId: string; requestType: string }>()
);

export const setOrderNo = createAction(
  '[Order Number] set to order number',
  props<{ orderNumber: string }>()
);

export const saveInvoiceDetails = createAction(
  '[Invoice Details] save the invoice details',
  props<{ body: { orderNumber: string; dateOfDelivery: number }, prescriptionId: string }>()
);

/**
 * Action for get ae form list.
 */
export const getAeForms = createAction(
  'AE REPORT | GET list of forms',
  props<{ pharmaId: string , process: string}>()
);

/**
 * Action for set ae form list.
 */
export const setAeFormList = createAction(
  'AE REPORT | SET list of forms',
  props<{ aeFormList: any[] }>()
);

/**
 * Action for submit ae form.
 */
export const submitAeReport = createAction(
  'AE REPORT | SUBMIT list of forms',
  props<{ form: any; pharmaCode: string; }>()
);

/**
 * Action for acknowledgment submit form.
 */
export const submitAcknowledgementReport = createAction(
  'ACKNOWLEDGEMENT REPORT | SUBMIT Ack form',
  props<{ id: string; form: any; }>()
);

/**
 * Action for submit updated ae form.
 */
export const updateAeReport = createAction(
  'AE REPORT | UPDATE AE Report',
  props<{ id: number, form: any; pharmaCode: string; }>()
);

/**
 * Action for submit ae form.
 */
export const setAeSubmitRes = createAction(
  'AE REPORT | SET SUBMIT list of forms',
  props<{ res: any }>()
);

/**
 * Action for submit ae form.
 */
export const resetAeSubmitRes = createAction(
  'AE REPORT | RESET SUBMIT list of forms'
);

/**
 * Action for submit ae form.
 */
export const setAckSubmitRes = createAction(
  'ACKNOWLEDGEMENT REPORT | SET SUBMIT Ack form',
  props<{ res: any }>()
);

/**
 * Action for submit ae form.
 */
export const resetAckSubmitRes = createAction(
  'ACKNOWLEDGEMENT REPORT | RESET SUBMIT Ack form'
);

/**
 * Action for submit ae form.
 */
export const resetAeFormList = createAction(
  'AE REPORT | RESET list of forms'
);

/**
 * Action for submit ae form.
 */
export const getSearchedPatientId = createAction(
  'PATIENT DETAIL | FORM get the patient detail',
  props<{ id: string; pharmaCode: string }>()
);

/**
 * Action for submit ae form.
 */
export const setSearchedPatientDetail = createAction(
  'PATIENT DETAIL | FORM set the patient detail',
  props<{ patientDetail: any }>()
);

/**
 * Action for submit ae form.
 */
export const resetSearchedPatientDetail = createAction(
  'PATIENT DETAIL | FORM reset the patient detail'
);

/**
 * Get priscription detail.
 */
export const getPharmaPap = createAction(
  'Home|API [GET PAP] Loading Pharma PAP Prescription',
  props<{ prescriptionId: string }>()
);

/**
 * Get priscription detail.
 */
export const setPharmaPap = createAction(
  'Home|API [SET PAP] Save the Pharma PAP Prescription',
  props<{ process: string }>()
);

/**
 * Get priscription detail.
 */
export const resetPharmaPap = createAction(
  'Home|API [RESET PAP] remove the Pharma PAP Prescription'
);

/**
 * This is used to get hospital.
 */
export const loadPatientHospitalList = createAction(
  '[Patient List] Load Hospitals',
  props<{enrollmentId: string}>()
);

/**
 * Login through patient data.
 */
export const updateTreatmentDetail = createAction(
  'Update treatment Details',
  props<{enrollmentId: string, formData: any}>()
);

export const updatePatientDetail = createAction(
  'Update Patient Details Sg',
  props<{formData: any , authToken?: string}>()
);
export const setPatientDetailsSg = createAction(
  'set Update Patient Details Sg',
  props<{editPatientDetails: any}>()
);

/**
 * Action for get ae report list.
 */
export const loadAeReports = createAction(
  'AE REPORT | GET list of Ae Reports',
  props<{ pharmaCode: string, keyword: string, activeTab: string, limit: string, page: string }>()
);

/**
 * Set Ae Report list data.
 */
export const setAeReportListData = createAction(
  'Home|API [Home page] set ae report List data',
  props<{ AeReportList: AeReportListResponse }>()
);

/**
 * reset patient list data.
 */
export const resetAeReportListData = createAction(
  'Home|API [Home page] reset Ae report List data',
);

/**
 * Action for get ae Report.
 */
export const getAeReport = createAction(
  'AE REPORT | GET ae Report',
  props<{ id: string }>()
);
/**
 * Action for set ae Report.
 */
export const setAeReport = createAction(
  'AE REPORT | SET Ae Report',
  props<{ aeReport: any[] }>()
);
export const resetAeReport = createAction(
  'AE REPORT | RESET Ae Report data'
);

/**
 * action to update status of aereport
 */
export const updateAeReportStatus = createAction(
  'AE REPORT | UPDATE Ae Report Status',
  props<{ id: string, status: string, reason: string }>()
);

/**
 * get user name of patient on ae report
 */

export const getUsersList = createAction(
  'USERNAME LIST | GET username',
  props<{ query: string, pharmaCode: string, page: string, limit: string }>()
);

export const setUsersList = createAction(
  'USERNAME LIST | SET username',
  props<{ usersList: UserDetailListResponse }>()
);
export const resetUsersList = createAction(
  'USERNAME LIST | RESET username'
);

/**
 * get Pharma registered program
 */
export const getPharmProgramList = createAction(
  'PROGRAM LIST | GET pharma program',
  props<{ keyword: string, pharma: string, limit: string }>()
);

export const setPharmaProgramList = createAction(
  'PROGRAM LIST | SET pharma Program',
  props<{ pharmaProgramList: any }>()
);
export const resetPharmaProgramList = createAction(
  'PROGRAM LIST | RESET pharma program'
);

export const getPharmaDetails = createAction(
  'PHARMA DETAIL LIST | GET pharma details',
  props<{ pharmaCode: string }>()
);

export const setPharmaDetails = createAction(
  'PHARMA DETAIL LIST | SET pharma details',
  props<{ pharmaDetailList: any }>()
);
export const resetPharmaDetailsList = createAction(
  'PHARMA DETAIL LIST | RESET pharma details'
);

/**
 * edit prescription details
 */
export const getPrescriptionEdit = createAction(
  'PROGRAM DETAIL | PATCH prescription details',
  props<{ prescriptionId: string, prescriptionEdit: any , authToken?: string }>()
);
export const setPrescriptionEdit = createAction(
  'PROGRAM DETAIL | SET prescription details edit response',
  props<{ prescriptionEditResponse: any }>()
);
