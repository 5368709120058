import { createSelector } from '@ngrx/store';
import * as fromAttendant from '../../reducers/attendant/attendant.reducer';
import * as fromRoot from '../../reducers/index';


export const attendant = (state: fromRoot.State) => state.attendant;
export const getAttendantRequestOTP = createSelector(
  attendant,
  (state: fromAttendant.State) => state.otpRequestToken
);
export const verifyAttendantRequestOTP = createSelector(
  attendant,
  (state: fromAttendant.State) => state.userRegistrationDetails
);
export const attendantResendOTP = createSelector(
  attendant,
  (state: fromAttendant.State) => state.otpResendStatus
);

export const getAttendantApiError = createSelector(
  attendant,
  (state: fromAttendant.State) => state.error
);
export const addAttendantLogin = createSelector(
  attendant,
  (state: fromAttendant.State) => state.attendantForm
);

export const addAttendantDCPM = createSelector(
  attendant,
  (state: fromAttendant.State) => state.attendantdcpm
);
export const addPatientSingapore = createSelector(
  attendant,
  (state: fromAttendant.State) => state.patientFormSg
);
export const updateTreatmentDetailsSg = createSelector(
  attendant,
  (state: fromAttendant.State) => state.treatmentUpdateSg
);
export const loadAttendantList = createSelector(
  attendant,
  (state: fromAttendant.State) => state.attendantlist
);
