import { createAction, props } from '@ngrx/store';
import { UserRegistrationStep } from '../../../models/attendant.model';


export const loadOTP = createAction(
  'Attendant| Login Request OTP',
  props<{phoneNumber: string, countryCode: string, userType: string}>()
);
export const setOtp = createAction(
  'Attendant|Set otp',
  props<{ otpRequestToken: string }>()
);
export const setErrors = createAction(
  'Attendant|Catch error of Attendant api',
  props<{ error: Error }>()
);
export const resetErrors = createAction(
  'Attendant|Reset error of Attendant api'
);
export const verifyOTP = createAction(
  'Attendant| Verify OTP',
  props<{ otp: string , otpRequestToken: string}>()
);
export const destroyToken = createAction(
  'Attendant| destroyTokenOnBack',
);
export const setVerifyOTPData = createAction(
  'Attendant| Set Verify OTP',
  props<{ verifyOtpResponse: UserRegistrationStep | null }>()
);
export const verifyAttendantCredRequest = createAction(
  '[Doctor] verify doctor credential request',
  props<{ otp: string, loginAuth: string, verificationSignature?: string, id?: number }>()
);

export const resendOTP = createAction(
  'Attendant| Resend OTP',
  props<{ otpRequestToken: string}>()
);

export const resendOTPData = createAction(
  'Attendant| Set Resend OTP status',
  props<{ resendOtpStatus: boolean}>()
);


export const submitForm = createAction(
  'Attendant | Submit Form',
  props<{ firstName: string; lastName: string; email: string; pharmaId?: number; otpRequestToken: string }>()
);
export const registerFormSuccess = createAction(
  'Attendant | Register Form Success',
  props<{ response: UserRegistrationStep | null }>()
);

export const submitFormFailure = createAction(
  'Attendant | Submit Form Failure',
  props<{ error: any }>()
);

export const addAttendantDCPM = createAction(
  'ATTENDANT|Submit form data',
  props<{ country_code: number, phone_number: string, first_name: string,
    last_name: string, email: string, pharma_id: number }>()
);

export const addAttendtSuccess = createAction(
  'ATTENDANT|Set submit attendant data',
  props<{response: any}>()
);
export const addAttendReset = createAction(
  'ATTENDANT|Reset submit attendant data',
);

export const resetVerifyOtp = createAction(
  'ATTENDANT|Reset verify attendant token',
);
export const loadAttendantList = createAction(
  'ATTENDANT|attendant data list',
  props<{ pharma_code: string, limit?: string, search?: any }>()
);

export const searchSuccess = createAction(
  'ATTENDANT | Search Data Success',
  props<{ attendantList: any | null }>()
);
export const listFailure = createAction(
  'Attendant | listFailure Failure',
  props<{ error: any }>()
);
export const attendantListReset = createAction(
  'ATTENDANT|Reset attendant data',
);
export const patientFormCreate = createAction(
  'Attendant | submit patient form',
  props<{ formData: any , authToken: string}>()
);
export const patientFormSuccess = createAction(
  'Attendant | set submit patient form data',
  props<{ response: any}>()
);
// export const updateSGTreatmentDetail = createAction(
//   'Update SG treatment Details',
//   props<{formData: any}>()
// );
export const updateSGTreatmentDetail = createAction(
  'Update SG treatment Details',
  props<{accessTokenValue: string , formData: any}>()
);
export const updateSGTreatmentDetailSuccess = createAction(
  'Update SG treatment Details Success',
  props<{response: any}>()
);
