/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';

/**
 * Rxjs import.
 */
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';


/**
 * Ngrx store imports.
 */
import { Store } from '@ngrx/store';
import * as LoginSelector from '../store/selectors/login/login.selectors';
import { HelperService } from '../helper.service';

/**
 * This interceptor is used for add the Authorization in http request header.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Loigin detail subscription.
   */
  public loginDetailSub: Subscription;

  /**
   * Used to store the id token.
   */
  public idToken: string;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loginDetailSub = this.store.select(LoginSelector.getUserCredential).pipe(
      take(1)
    ).subscribe(data => {
      if (data) {
        this.idToken = data.accessToken;
        if (request.url.indexOf('/v1/users/refresh') !== -1) {
          this.idToken = data.refreshToken;
        }
      }
    });

    let authReq;
    if (request.url.indexOf('amazonaws') !== -1) {
      authReq = request.clone({});
    } else {
      let authHeaders: HttpHeaders;
      authHeaders = request.headers.set('country', (localStorage.getItem('country')) ?? 'PH')
      .set('language', (localStorage.getItem('lang')) ?? 'en');
      if (!this.helperService.addCustomAuthToken && this.idToken) {
        authHeaders = authHeaders.set('Authorization', 'Bearer ' + this.idToken);
      }
      authReq = request.clone({
        headers: authHeaders
      });
    }
    return next.handle(authReq);
  }

  /**
   * Created all required instances.
   */
  constructor(private store: Store, private helperService: HelperService, ) { }

}
